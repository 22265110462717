export const useStartpageStore = defineStore('startpageStore', {
  state: () => ({
    showFeatureAlert: true,
    showProfileProgressCard: true,
    showDisclaimerModal: true,
    showRedirectInfoModal: false,
    doNotShowRedirectInfoModalAgain: false,
    classicDashboardUrl: '',
    primaryButtonText: '',
  }),
  actions: {
    hideFeatureAlert() {
      this.showFeatureAlert = false;
    },
    hideProfileProgressCard() {
      this.showProfileProgressCard = false;
    },
    redirectToClassicDashboard() {
      const nuxtConfig = useRuntimeConfig();

      navigateTo(`${nuxtConfig.public.oldDashboardUrl}/${this.classicDashboardUrl}`, {
        external: true,
      });
    },
  },
  getters: {
    featureAlert: (state) => state.showFeatureAlert,
    profileProgressCard: (state) => state.showProfileProgressCard,
  },
  persist: {
    storage: persistedState.cookies,
    paths: [
      'showFeatureAlert',
      'showProfileProgressCard',
      'showDisclaimerModal',
      'doNotShowRedirectInfoModalAgain',
    ],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStartpageStore, import.meta.hot));
}
